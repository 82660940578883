<template>
  <div>
    <el-form :inline="true" :model="filter" size="mini">
      <el-form-item label="开始日期">
        <el-date-picker
          value-format="yyyy-MM-dd"
          v-model="filter.begin"
          type="date"
          placeholder="选择日期"
          style="width: 150px"
        ></el-date-picker>
      </el-form-item>
      <el-form-item label="结束日期">
        <el-date-picker
          value-format="yyyy-MM-dd"
          v-model="filter.end"
          type="date"
          placeholder="选择日期"
          style="width: 150px"
        ></el-date-picker>
      </el-form-item>
      <el-form-item label="项目">
        <project-select v-model="filter.projectId"></project-select>
      </el-form-item>
      <el-form-item label="员工">
        <base-select info="Employee" v-model="filter.employeeId"></base-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-refresh" @click="getData"
          >刷新</el-button
        >
        <el-button type="success" @click="excel">
          <span class="iconfont iconexcel" style="font-size: 12px"></span>
          导出
        </el-button>
      </el-form-item>
    </el-form>

    <pl-table
      :data="data"
      stripe
      border
      :height="tableHeight"
      :row-height="rowHeight"
      use-virtual
      ref="table"
      @row-dblclick="dblclick"
    >
      <el-table-column
        label="单据编号"
        prop="billCode"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column label="单据日期" prop="billDate" width="90">
        <template slot-scope="scope">{{
          scope.row.billDate | datetime
        }}</template>
      </el-table-column>
      <el-table-column
        label="单据类型"
        prop="billName"
        width="90"
      ></el-table-column>
      <el-table-column
        label="项目"
        prop="projectName"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        label="员工"
        prop="employeeName"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column label="单据金额" prop="billAmount" show-overflow-tooltip>
        <template slot-scope="scope">
          <span
            :style="{ color: scope.row.billAmount < 0 ? 'red' : 'inherit' }"
            >{{ scope.row.billAmount | thousands }}</span
          >
        </template>
      </el-table-column>
      <el-table-column
        label="结余金额"
        prop="balanceAmount"
        show-overflow-tooltip
      >
        <template slot-scope="scope">
          <span
            :style="{ color: scope.row.balanceAmount < 0 ? 'red' : 'inherit' }"
            >{{ scope.row.balanceAmount | thousands }}</span
          >
        </template>
      </el-table-column>
      <el-table-column
        label="备注"
        prop="remark"
        show-overflow-tooltip
      ></el-table-column>
    </pl-table>
  </div>
</template>

<script>
import BaseSelect from "@/components/BaseSelect.vue";
import ProjectSelect from "@/components/ProjectSelect.vue";
import setName from "@/common/setName";
import tableHeight from "@/common/tableHeightMixins";
import toExcel from "@/common/toExcel";
export default {
  mixins: [tableHeight],
  components: {
    BaseSelect,
    ProjectSelect,
  },
  data() {
    return {
      filter: {
        begin: this.$moment().subtract(1, "months").format("YYYY-MM-DD"),
        end: this.$moment().format("YYYY-MM-DD"),
      },
      data: [],
    };
  },
  activated() {
    this.getData();
  },
  methods: {
    getData() {
      let loading = this.$loading({
        lock: true,
        text: "加载中...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      let params = { ...this.filter };
      this.$get("EmployeeFlow/GetEmployeeFlow", params)
        .then((r) => {
          r.forEach((item) => {
            setName("Project", item, "projectId", "projectName");
            setName("Employee", item, "employeeId", "employeeName");
          });
          this.data = r;
        })
        .finally(() => {
          loading.close();
        });
    },
    dblclick(row) {
      ////1借款2还款3项目费用4人工费用
      switch (row.billType) {
        case 1:
        case 2:
          if (this.hasPermission("Borrower")) {
            this.$router.push({
              name: "BorrowerItem",
              query: { id: row.billId },
            });
          }
          break;
        case 3:
          if (this.hasPermission("Expense")) {
            this.$router.push({
              name: "ExpenseItem",
              query: { id: row.billId },
            });
          }
          break;
        case 4:
          if (this.hasPermission("Labor")) {
            this.$router.push({
              name: "LaborItem",
              query: { id: row.billId },
            });
          }
          break;
      }
    },
    excel() {
      let list = [];
      this.data.forEach((item) => {
        list.push({
          billCode: item.billCode || "",
          billDate: item.billDate
            ? this.$moment(item.billDate).format("YYYY-MM-DD")
            : "",
          billName: item.billName,
          projectName: item.projectName,
          employeeName: item.employeeName,
          billAmount: this.amountFormat(item.billAmount),
          balanceAmount: this.amountFormat(item.balanceAmount),
          remark: item.remark,
        });
      });
      let column = [
        "billCode",
        "billDate",
        "billName",
        "projectName",
        "employeeName",
        "billAmount",
        "balanceAmount",
        "remark",
      ];
      let header = [
        "单据编号",
        "单据日期",
        "单据类型",
        "项目",
        "员工",
        "单据金额",
        "结余金额",
        "备注",
      ];
      toExcel(header, column, list, "借还款台账");
    },
  },
};
</script>

<style>
</style>